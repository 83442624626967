/* font-family: 'Courgette', cursive;
font-family: 'Sriracha', cursive;
font-family: 'Delius', cursive; */

/* font-family: 'Courgette', cursive;
font-family: 'Sriracha', cursive;
font-family: 'Delius', cursive; */


/* #container{
    margin: 10%;
} */
#root > div > div {
    z-index: 99999 !important;
  }
  /* main {
    padding-left: 300px;
  } */
  .Info-div{
    margin-bottom: 0;
    max-height: 10%;
  }
  #Name{
    margin-top: 3vh;
    margin-bottom: 0;
  }
  #Info{
    margin-top: 3vh;
    margin-bottom: 0%;
    max-height: 50%;
  }
  .btn{
    background-image: -webkit-linear-gradient(20deg, rgb(199, 137, 166), rgb(227, 133, 145));
    background-image: linear-gradient(70deg, rgb(199, 137, 166), rgb(227, 133, 145));
    background-repeat: no-repeat;
    color:white;
    box-shadow: 5px 5px 5px grey;
    margin-left: 5%;
    height: 50px;
    width: 120px;
    font-size: 20px;
    border-radius: 5%;
    margin-top: 2vh;
    font-family: 'Sriracha', cursive;
    
  }

  .Footer{
    margin-top: 10%;
    background-image: -webkit-linear-gradient(20deg, rgb(199, 137, 166), rgb(227, 133, 145));
    background-image: linear-gradient(70deg, rgb(199, 137, 166), rgb(227, 133, 145));
    background-repeat: no-repeat;
    border-top: solid white 1px;
    color:white;
    position: fixed;
    bottom:0;
    left:0;
    right:0;
    max-height: 8em;
  }

  @media only screen and (max-width : 375px) {
     main{
      padding-left: 0;
    }
    #jenae{
      margin-top: 10vh;
    }
  } 
  .Container{
    margin-bottom: 40%;
  }

/* font-family: 'Courgette', cursive;
font-family: 'Sriracha', cursive;
font-family: 'Arimo', sans-serif;
font-family: 'Fjalla One', sans-serif;
font-family: 'Ubuntu Condensed', sans-serif; */




#color{
background-image: -webkit-linear-gradient(20deg, rgb(199, 137, 166), rgb(227, 133, 145));
background-image: linear-gradient(70deg, rgb(199, 137, 166), rgb(227, 133, 145));
background-repeat: no-repeat;
color:white;
font-family: 'Fjalla One', sans-serif;
font-size: 20px;
border-radius:2%;
box-shadow: 5px 5px 5px grey;
margin-top: 10%;
margin-bottom: 0;
align-content: center;
max-width: 40em;
min-width: 10em;
}
.Title{
    font-family: 'Fjalla One', sans-serif;
    font-size: 1.8em;
}h3{
    align-content: center;
}

/* .Qualifications{
    /* margin: 2%; */
    /* align-content: center; 
} */

   



.Picture{
    margin-top: 15%; 
    margin-left: 5%; 
    /* margin-bottom: -150%; */
    max-height: 45vh;
    min-width: auto;
    border-radius: 50%;
    box-shadow: 5px 5px 5px grey;
}
img{
    margin:0;
}
/* font-family: 'Courgette', cursive;
font-family: 'Sriracha', cursive;
font-family: 'Arimo', sans-serif;
font-family: 'Fjalla One', sans-serif;
font-family: 'Ubuntu Condensed', sans-serif; */


#jenae{
    background: -webkit-linear-gradient(70deg, rgb(199, 137, 166), rgb(227, 133, 145));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
      margin-top: 5vh;
      /* margin-left:20%; */
      margin-right: 0px;
      /* padding-left: 15%; */
      align-content: center;
      font-family: 'Courgette', cursive;
      /* background-color:white; */
      font-size: 300%;
}


.Facts{
background-image: -webkit-linear-gradient(20deg, rgb(199, 137, 166), rgb(227, 133, 145));
background-image: linear-gradient(70deg, rgb(199, 137, 166), rgb(227, 133, 145));
background-repeat: no-repeat;
font-family: 'Fjalla One', sans-serif;
color:white;
font-size: 20px;
align-content: center;
/* margin-top:-500; */
border-radius: 2% 2%;
box-shadow: 5px 5px 5px grey;
width: 100%;
height: auto;
min-width: 10em;
/* margin-left:4%; */
/* z-index: 5; */
/* margin: 2%; */
}
.Fact{
    font-family: 'Fjalla One', sans-serif;
    align-content: center;
    font-size: 3em; 
    padding:2%;
}
.CardBtn{
    width: auto;
    font-size:100%;
}
.Card{
    margin-bottom: 20%;
}
#hey{
    font-family: 'Sriracha', cursive;
    align-content: center;
    font-size: 1.4em;
    color:rgb(199, 137, 166);
    
}
.Links{
    font-family: 'Sriracha', cursive;
    font-size: 1.7em;
    align-content: center;
    color:rgb(199, 137, 166);
   
}
.Links:hover{
    color:rgb(131, 61, 110);
}
.Heading{
    align-content: center;
    font-size: 1.4em;
    font-family: 'Sriracha', cursive;
    color:rgb(204, 139, 154);

}
.space
{
    margin-top:2%;
}
.Text{
    color:rgb(131, 61, 110);
}
.Texts{
    color:rgb(131, 61, 110);
}
.Texts:hover{
    /* color:rgb(237, 175, 101); */
    color:rgb(204, 139, 154);
}
.Lists{
    color:rgb(131, 61, 110);
}
.End{
    margin-bottom:40%;
}
/* font-family: 'Courgette', cursive;
font-family: 'Sriracha', cursive;
font-family: 'Delius', cursive; */

 body {
    background-image: url(/static/media/florals1.0f62d93a.png);
    background-size: 430px 400px;
    background-position: right top; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image*/
    margin-top: 2%; 
    background-color: rgb(237, 220, 233, .4)
 }
   
