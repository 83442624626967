.Picture{
    margin-top: 15%; 
    margin-left: 5%; 
    /* margin-bottom: -150%; */
    max-height: 45vh;
    min-width: auto;
    border-radius: 50%;
    box-shadow: 5px 5px 5px grey;
}
img{
    margin:0;
}