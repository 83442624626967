/* font-family: 'Courgette', cursive;
font-family: 'Sriracha', cursive;
font-family: 'Arimo', sans-serif;
font-family: 'Fjalla One', sans-serif;
font-family: 'Ubuntu Condensed', sans-serif; */


#jenae{
    background: -webkit-linear-gradient(70deg, rgb(199, 137, 166), rgb(227, 133, 145));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
      margin-top: 5vh;
      /* margin-left:20%; */
      margin-right: 0px;
      /* padding-left: 15%; */
      align-content: center;
      font-family: 'Courgette', cursive;
      /* background-color:white; */
      font-size: 300%;
}

