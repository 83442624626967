.CardBtn{
    width: auto;
    font-size:100%;
}
.Card{
    margin-bottom: 20%;
}
#hey{
    font-family: 'Sriracha', cursive;
    align-content: center;
    font-size: 1.4em;
    color:rgb(199, 137, 166);
    
}