/* font-family: 'Courgette', cursive;
font-family: 'Sriracha', cursive;
font-family: 'Arimo', sans-serif;
font-family: 'Fjalla One', sans-serif;
font-family: 'Ubuntu Condensed', sans-serif; */




#color{
background-image: linear-gradient(70deg, rgb(199, 137, 166), rgb(227, 133, 145));
background-repeat: no-repeat;
color:white;
font-family: 'Fjalla One', sans-serif;
font-size: 20px;
border-radius:2%;
box-shadow: 5px 5px 5px grey;
margin-top: 10%;
margin-bottom: 0;
align-content: center;
max-width: 40em;
min-width: 10em;
}
.Title{
    font-family: 'Fjalla One', sans-serif;
    font-size: 1.8em;
}h3{
    align-content: center;
}

/* .Qualifications{
    /* margin: 2%; */
    /* align-content: center; 
} */

   


