/* font-family: 'Courgette', cursive;
font-family: 'Sriracha', cursive;
font-family: 'Delius', cursive; */


/* #container{
    margin: 10%;
} */
#root > div > div {
    z-index: 99999 !important;
  }
  /* main {
    padding-left: 300px;
  } */
  .Info-div{
    margin-bottom: 0;
    max-height: 10%;
  }
  #Name{
    margin-top: 3vh;
    margin-bottom: 0;
  }
  #Info{
    margin-top: 3vh;
    margin-bottom: 0%;
    max-height: 50%;
  }
  .btn{
    background-image: linear-gradient(70deg, rgb(199, 137, 166), rgb(227, 133, 145));
    background-repeat: no-repeat;
    color:white;
    box-shadow: 5px 5px 5px grey;
    margin-left: 5%;
    height: 50px;
    width: 120px;
    font-size: 20px;
    border-radius: 5%;
    margin-top: 2vh;
    font-family: 'Sriracha', cursive;
    
  }

  .Footer{
    margin-top: 10%;
    background-image: linear-gradient(70deg, rgb(199, 137, 166), rgb(227, 133, 145));
    background-repeat: no-repeat;
    border-top: solid white 1px;
    color:white;
    position: fixed;
    bottom:0;
    left:0;
    right:0;
    max-height: 8em;
  }

  @media only screen and (max-width : 375px) {
     main{
      padding-left: 0;
    }
    #jenae{
      margin-top: 10vh;
    }
  } 
  .Container{
    margin-bottom: 40%;
  }
